<template>
  <section>
    <b-card>
      <validation-form
        ref="validationForm"
        :submit-buttons="{
          loading: form.isLoading,
          cancel: isNewVenue || $route.query.event,
        }"
        @cancel="$attrs['go-back']"
        @validated="isNewVenue ? create() : update()"
      >
        <b-row>
          <!-- Name -->
          <b-col sm="6">
            <form-validation-field
              v-model="form.name"
              vid="name"
              name="venue-name"
              rules="required"
              label="Name*"
              placeholder="Name"
              aria-autocomplete="none"
              :autofocus="isNewVenue"
            />
          </b-col>

          <!-- Abbreviation -->
          <b-col sm="6">
            <form-validation-field
              v-model="form.abbreviation"
              vid="abbreviation"
              name="venue-abbreviation"
              rules="required"
              label="Abbreviation*"
              placeholder="Abbreviation"
              aria-autocomplete="none"
            />
          </b-col>
        </b-row>

        <form-address
          :address="form.address || undefined"
          @update:address="form.address = $event"
        />
      </validation-form>
    </b-card>

    <!-- Delete -->
    <card-delete
      v-if="!isNewVenue"
      item-name="venue"
      alert-body="Once you delete this venue, past records will be permanently deleted for all leagues. This action cannot be undone."
      @delete="$store.commit('app/deleteModal/UPDATE_DELETE_MODAL',{
        title: 'Delete Venue',
        itemName: form.name,
        endpoint: `/venues/${form.id}`,
        afterDelete: $attrs['go-back']
      })"
    />
  </section>
</template>

<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import FormAddress from '@/components/forms/FormAddress.vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import CardDelete from '@/components/cards/CardDelete.vue'

import Form from '@/forms/Form'

export default {
  name: 'OrganizationVenueInformation',
  components: {
    BCard,
    BRow,
    BCol,
    ValidationForm,
    FormAddress,
    FormValidationField,
    CardDelete,
  },
  data() {
    return {
      form: new Form({
        name: '',
        abbreviation: '',
        address: null,
        events: null,
      }),
    }
  },
  computed: {
    isNewVenue() {
      return !this.$route.params.venue
    },
  },
  mounted() {
    Object.assign(this.form, this.$attrs.information)
    this.form.validationObserver = this.$refs.validationForm.$refs.observer

    if (this.$route.query.event) {
      this.form.events = [this.$route.query.event]
    }
  },
  methods: {
    create(createNew) {
      this.form.post(`/organizations/${this.$store.state.account.activeOrganization}/venues`, createNew)
        .then(response => {
          if (createNew) {
            document.getElementById('name').focus()
            return
          }

          const { data } = response

          this.$emit('update', data)

          this.$router.replace({ name: 'organization.venue.playing-surfaces', params: { venue: data.id }, query: this.$route.query })
        })
    },
    update() {
      this.form.put(`/venues/${this.$route.params.venue}`)
        .then(response => {
          Object.assign(this.form, response.data)
        })
    },
  },
}
</script>

<style scoped>

</style>

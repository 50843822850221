var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',[_c('validation-form',{ref:"validationForm",attrs:{"submit-buttons":{
        loading: _vm.form.isLoading,
        cancel: _vm.isNewVenue || _vm.$route.query.event,
      }},on:{"cancel":_vm.$attrs['go-back'],"validated":function($event){_vm.isNewVenue ? _vm.create() : _vm.update()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('form-validation-field',{attrs:{"vid":"name","name":"venue-name","rules":"required","label":"Name*","placeholder":"Name","aria-autocomplete":"none","autofocus":_vm.isNewVenue},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('form-validation-field',{attrs:{"vid":"abbreviation","name":"venue-abbreviation","rules":"required","label":"Abbreviation*","placeholder":"Abbreviation","aria-autocomplete":"none"},model:{value:(_vm.form.abbreviation),callback:function ($$v) {_vm.$set(_vm.form, "abbreviation", $$v)},expression:"form.abbreviation"}})],1)],1),_c('form-address',{attrs:{"address":_vm.form.address || undefined},on:{"update:address":function($event){_vm.form.address = $event}}})],1)],1),(!_vm.isNewVenue)?_c('card-delete',{attrs:{"item-name":"venue","alert-body":"Once you delete this venue, past records will be permanently deleted for all leagues. This action cannot be undone."},on:{"delete":function($event){return _vm.$store.commit('app/deleteModal/UPDATE_DELETE_MODAL',{
      title: 'Delete Venue',
      itemName: _vm.form.name,
      endpoint: ("/venues/" + (_vm.form.id)),
      afterDelete: _vm.$attrs['go-back']
    })}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <form-validation-field
    id="time-zone"
    name="time zone"
    component="BFormSelect"
    :label="$attrs.label || 'Time Zone'"
    :options="timeZones"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

export default {
  components: {
    FormValidationField,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    timeZones() {
      const timeZones = this.$store.state.app.address.timeZones.map(timeZone => ({
        value: timeZone.id,
        text: `${timeZone.name} ${timeZone.offset}`,
      }))

      return [
        { text: 'Select Time Zone', value: null, disabled: true },
        ...timeZones,
      ]
    },
  },
}
</script>

<style scoped>

</style>

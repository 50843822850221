<template>
  <b-card no-body>
    <b-card-title>
      Address
    </b-card-title>

    <b-row>
      <!-- Street Address -->
      <b-col sm="6">
        <form-validation-field
          v-model="localAddress.street_address"
          vid="address.street_address"
          name="street address"
          rules="required"
          label="Street Address*"
          placeholder="Street Address"
        />
      </b-col>

      <!-- City -->
      <b-col sm="6">
        <form-validation-field
          v-model="localAddress.city"
          vid="address.city"
          name="city"
          rules="required"
          label="City*"
          placeholder="City"
        />
      </b-col>

      <!-- State -->
      <b-col sm="6">
        <form-validation-field
          v-model="localAddress.state"
          component="BFormSelect"
          vid="address.state"
          name="state"
          rules="required"
          mode="eager"
          label="State*"
          value-field="value"
          text-field="text"
          :options="statesOptions"
        />
      </b-col>

      <!-- Postal Code -->
      <b-col sm="6">
        <form-validation-field
          v-model="localAddress.postal_code"
          vid="address.postal_code"
          name="postal code"
          rules="required"
          label="Postal Code*"
          placeholder="Postal Code"
        />
      </b-col>

      <!-- Country -->
      <b-col sm="6">
        <form-validation-field
          v-model="localAddress.country"
          component="BFormSelect"
          vid="address.country"
          name="country"
          rules="required"
          mode="lazy"
          label="Country*"
          value-field="value"
          text-field="text"
          :options="countriesOptions"
        />
      </b-col>

      <!-- Time Zone -->
      <b-col sm="6">
        <select-time-zone
          v-model="localAddress.time_zone"
          vid="address.time_zone"
          rules="required"
          mode="lazy"
          label="Time Zone*"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BRow, BCol,
} from 'bootstrap-vue'
import SelectTimeZone from '@/components/forms/selects/SelectTimeZone.vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    FormValidationField,
    SelectTimeZone,
  },
  props: {
    address: {
      type: Object,
      default: () => ({
        street_address: '',
        city: '',
        state: null,
        postal_code: '',
        time_zone: null,
        country: null,
      }),
    },
  },
  data() {
    return {
      localAddress: '',
    }
  },
  computed: mapGetters('app/address', ['statesOptions', 'countriesOptions']),
  watch: {
    address(value) {
      this.localAddress = value
    },
    localAddress: {
      handler(value) {
        this.$emit('update:address', value)
      },
      deep: true,
    },
  },
  mounted() {
    this.localAddress = this.address
  },
}
</script>

<style scoped>

</style>
